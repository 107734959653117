import React, { useEffect, useState } from 'react';
// Import Highcharts
import Highcharts from 'highcharts/highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import drilldown from 'highcharts/modules/drilldown.js';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);

const staticOptions = {
  chart: {
    type: 'column',
    borderRadius: 10
  },
  title: {
    text: ''
  },
  subtitle: {},
  xAxis: {
    type: 'category'
  },
  yAxis: {
    title: {
      text: 'Count'
    }
  },
  legend: {
    enabled: true
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true
      }
    }
  },
  credits: {
    enabled: false
  },
  series: [
    {
      name: 'Daily Unique Count',
      colorByPoint: false,
      data: []
    }
  ],
  drilldown: {
    allowPointDrilldown: true,
    breadcrumbs: {
      showFullPath: true
    },
    series: []
  },

  colors: [
    'rgba( 0,   154, 253, 0.9 )', // bright blue
    'rgba( 253, 99,  0,   0.9 )', // bright orange
    'rgba( 40,  40,  56,  0.9 )', // dark
    'rgba( 253, 0,   154, 0.9 )', // bright pink
    'rgba( 154, 253, 0,   0.9 )', // bright green
    'rgba( 145, 44,  138, 0.9 )', // mid purple
    'rgba( 45,  47,  238, 0.9 )', // mid blue
    'rgba( 177, 69,  0,   0.9 )', // dark orange
    'rgba( 140, 140, 156, 0.9 )', // mid
    'rgba( 238, 46,  47,  0.9 )', // mid red
    'rgba( 44,  145, 51,  0.9 )', // mid green
    'rgba( 103, 16,  192, 0.9 )' // dark purple
  ]
};

const ColumnChart = React.memo(
  ({ name, categories, count, drilldown = {}, setZoom, setEnableGetData, allowPointDrilldown }) => {
    const [options, setOptions] = useState(staticOptions);
    const theme = useTheme();

    const handleZoom = event => {
      if (event.type === 'selection') {
        if (event.resetSelection) {
          setZoom([0, 0]);
        } else if (event.xAxis && event.xAxis.length) {
          setZoom([Math.ceil(event.xAxis[0].min), Math.floor(event.xAxis[0].max)]);
        }
      }
    };

    const handleDrilldown = () => {
      setEnableGetData(false);
    };
    const handleDrillup = event => {
      if (event?.seriesOptions?._levelNumber === 0) setEnableGetData(true);
    };

    useEffect(() => {
      staticOptions.chart.backgroundColor = theme.palette.background.highCharts;
      staticOptions.title.text = name;
      staticOptions.title.style = { color: theme.palette.text.primary };
      staticOptions.subtitle.style = { color: theme.palette.text.secondary };
      staticOptions.legend.itemStyle = { color: theme.palette.text.primary };
      staticOptions.yAxis.labels = {
        ...staticOptions.yAxis.labels,
        style: { color: theme.palette.text.primary }
      };
      staticOptions.xAxis.labels = {
        ...staticOptions.xAxis.labels,
        style: { color: theme.palette.text.primary }
      };
      staticOptions.yAxis.title = {
        ...staticOptions.yAxis.title,
        style: { color: theme.palette.text.primary }
      };
      staticOptions.xAxis.title = {
        ...staticOptions.xAxis.title,
        style: { color: theme.palette.text.primary }
      };
      staticOptions.drilldown.allowPointDrilldown = allowPointDrilldown;
      staticOptions.drilldown.activeAxisLabelStyle = { color: theme.palette.text.primary };
    }, [allowPointDrilldown, name, theme.palette]);

    useEffect(() => {
      setOptions({
        ...staticOptions,
        title: {
          ...staticOptions.title,
          text: name
        },
        subtitle: {
          ...staticOptions.subtitle,
          text: categories ? null : 'Click the columns to view hourly uniques.'
        },
        series: count,
        drilldown: {
          ...staticOptions.drilldown,
          ...drilldown
        },
        xAxis: {
          ...staticOptions.xAxis,
          categories
        },
        yAxis: {
          ...staticOptions.yAxis
        },
        chart: {
          ...staticOptions.chart,
          events: {
            selection: handleZoom,
            drilldown: handleDrilldown,
            drillup: handleDrillup
          }
        },
        legend: {
          ...staticOptions.legend
          // enabled: Boolean(categories)
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, count, setZoom, setEnableGetData, drilldown, categories]);

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
);

ColumnChart.propTypes = {
  name: PropTypes.string,
  categories: PropTypes.array,
  count: PropTypes.array,
  drilldown: PropTypes.object,
  setZoom: PropTypes.func,
  setEnableGetData: PropTypes.func,
  allowPointDrilldown: PropTypes.bool
};

export default ColumnChart;
