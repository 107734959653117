import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import ColumnChart from '../../components/highCharts/ColumnChart';
import { addDays } from 'date-fns';
import { baNodeService } from '../../services/ba-node.service';
import { useSelector } from 'react-redux';
import { getProbes } from '../../features/probesSlice';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const DailyUniques = React.memo(
  ({ className, title, installation, locations, selectedDates, layout, ...rest }) => {
    const classes = useStyles();
    const [btleUniquesDayDrilldown, setBtleUniquesDayDrilldown] = useState({});
    const [btleUniquesDayData, setBtleUniquesDayData] = useState([]);
    const [btleUniquesMax, setBtleUniquesMax] = useState(0);
    const [enableGetData, setEnableGetData] = useState(true);
    const [zoom, setZoom] = useState([0, 0]);
    const [btleMinutesData, setBtleMinutesData] = useState({});
    const probes = useSelector(getProbes);
    // const [loadingValues, setLoadingValues] = useState(false);

    const getBtleCounts = async () => {
      const daysList = [];
      const locationIds = locations.map(l => l.location.id);

      for (const day of selectedDates) {
        let dayStart = new Date(day).setHours(installation.dayStart || 0, 0, 0, 0);
        if (dayStart > day) dayStart -= 86400000; // e.g. if dayStart is 3 am and time is 2 am
        const dayEnd = addDays(new Date(dayStart), 1).setHours(installation.dayStart || 0, 0, 0, 0);
        daysList.push(`${dayStart},${dayEnd}`);
      }
      const retObj = await baNodeService.getBtleCountsForEvent(locationIds, daysList, probes);

      setBtleMinutesData({ ...(retObj || {}) });
    };

    useEffect(() => {
      if (installation.loaded && locations.length) {
        if (!enableGetData || !locations?.length || !selectedDates?.length) return;

        getBtleCounts().catch(err => console.error(err));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDates, installation, locations]);

    useEffect(() => {
      if (!btleMinutesData || !Object.keys(btleMinutesData).length) return;

      const locationsObj = locations.reduce(
        (locs, loc) => ({
          ...locs,
          [loc.location.id]: loc
        }),
        {}
      );
      let maxUniques = 0;
      const locationDayData = {};
      const hourData = [];
      const minuteData = [];
      const days = Object.keys(btleMinutesData);
      days.forEach(day => {
        const dataList = btleMinutesData[day];
        const keys = Object.keys(dataList);
        keys.forEach(key => {
          const locationData = dataList[key];
          const location = locationsObj[key];

          const dayCount = locationData.counts.reduce(
            ({ day, hours }, minute) => {
              const hour = Math.floor(minute.timestamp / 3600000) * 3600000;
              return {
                day: day + minute.count,
                hours: {
                  ...hours,
                  [hour]: {
                    hour,
                    count: (hours[hour]?.count || 0) + minute.count,
                    minutes: [...(hours[hour]?.minutes || []), [minute.timestamp, minute.count]] // hours[hour].minutes
                  } // hours[hour]
                } // hours
              }; // return
            },
            { day: 0, hours: {} }
          ); // datum.count.reduce(
          const hoursArray = Object.values(dayCount.hours);
          maxUniques = Math.max(
            maxUniques,
            hoursArray.length
              ? hoursArray.sort((a, b) => b.count - a.count)[0].count * 1.1 ||
                  location?.location?.capacity ||
                  100
              : location?.location?.capacity || 100
          );
          const dayStr = new Date(locationData.fromTime).toLocaleDateString();
          locationDayData[dayStr] = [
            ...(locationDayData[dayStr] || []),
            {
              time: locationData.fromTime,
              name: location?.location?.name,
              y: dayCount.day,
              drilldown: `${location?.location?.id}-${dayStr}`
            }
          ];
          hourData.push({
            name: `${location?.type}: ${location?.location?.name}-${dayStr}`,
            id: `${location?.location?.id}-${dayStr}`,
            data: hoursArray
              .sort((a, b) => a.hour - b.hour)
              .map(hour => ({
                name: `${new Date(hour.hour).toLocaleTimeString()} to ${new Date(
                  hour.hour + 3540000
                ).toLocaleTimeString()}`,
                y: hour.count,
                drilldown: `${location?.location?.id}-${hour.hour}`
              }))
          }); //  hourData.push({
          hoursArray.forEach(hour => {
            minuteData.push({
              name: `${location?.location?.name}-${dayStr} ${new Date(
                hour.hour
              ).toLocaleTimeString()} to ${new Date(hour.hour + 3540000).toLocaleTimeString()}`,
              id: `${locationData?.location?.id}-${hour.hour}`,
              data: hour.minutes.map(([timestamp, count]) => [
                new Date(timestamp).toLocaleTimeString(),
                count
              ])
            }); // minuteData.push()
          }); // hoursArray.forEach()
        });
      });

      setBtleUniquesDayData(
        Object.entries(locationDayData).map(([dayName, dayData]) => {
          return {
            name: dayName,
            id: dayName,
            color: null,
            data: dayData
              .sort((a, b) => a.time - b.time)
              .map(day => ({
                name: day.name,
                y: day.y,
                drilldown: day.drilldown
              }))
          };
        })
      );
      setBtleUniquesDayDrilldown({ series: [...hourData, ...minuteData] });
      setBtleUniquesMax(maxUniques);
    }, [btleMinutesData, locations]);

    return (
      <>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader title={'Daily Unique Count'} />
          <CardContent>
            <ColumnChart
              name={title}
              count={btleUniquesDayData}
              drilldown={btleUniquesDayDrilldown}
              capacity={btleUniquesMax}
              setEnableGetData={setEnableGetData}
              setZoom={setZoom}
              zoom={zoom}
              allowPointDrilldown={false}
            />
          </CardContent>
        </Card>
      </>
    );
  }
);

DailyUniques.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  installation: PropTypes.object,
  locations: PropTypes.array,
  selectedDates: PropTypes.array,
  layout: PropTypes.string
};

export default DailyUniques;
