import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';
import { Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import TimeSeriesZoomable from '../../components/highCharts/TimeSeriesZoomable';
import { baNodeService } from '../../services/ba-node.service';
import { useSelector } from 'react-redux';
import { getProbes } from '../../features/probesSlice';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const MinuteGraphs = React.memo(
  ({ className, title, installation, locations, selectedDates, layout, ...rest }) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [zoom, setZoom] = useState([0, 0]);
    const [wifiMinutesData, setWifiMinutesData] = useState({});
    const [maxWifiCount, setMaxWifiCount] = useState(0);
    const probes = useSelector(getProbes);

    const getWifiCounts = async () => {
      const daysList = [];
      const locationIds = locations.map(l => l.location.id);

      for (const day of selectedDates) {
        let dayStart = new Date(day).setHours(installation.dayStart || 0, 0, 0, 0);
        if (dayStart > day) dayStart -= 86400000; // e.g. if dayStart is 3 am and time is 2 am
        const dayEnd = addDays(new Date(dayStart), 1).setHours(installation.dayStart || 0, 0, 0, 0);
        daysList.push(`${dayStart},${dayEnd}`);
      }
      const retObj = await baNodeService.getWifiCountsForEvent(locationIds, daysList, probes);

      setWifiMinutesData({ ...(retObj || {}) });
    };

    useEffect(() => {
      if (installation.loaded && locations.length) {
        if (!locations?.length || !selectedDates?.length) return;

        getWifiCounts().catch(err => console.error(err));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDates, installation, locations]);

    useEffect(() => {
      if (!wifiMinutesData || !Object.keys(wifiMinutesData).length) return;
      const locationsObj = locations.reduce(
        (locs, loc) => ({
          ...locs,
          [loc.location.id]: loc
        }),
        {}
      );
      let dataToSet = [];
      let maxWifiCount = 0;
      const days = Object.keys(wifiMinutesData);
      days.forEach(day => {
        const dataList = wifiMinutesData[day];
        const keys = Object.keys(dataList);

        keys.forEach(key => {
          const minuteData = dataList[key];
          const location = locationsObj[key];

          const dayStr = new Date(minuteData.fromTime).toLocaleDateString();
          const dateOffset =
            layout === 'overlay'
              ? minuteData.fromTime + (installation.timezoneOffset - installation.dayStart - 24) * 3600000
              : 0;

          maxWifiCount = Math.max(
            maxWifiCount,
            minuteData.counts.length
              ? minuteData.counts.sort((a, b) => b.count - a.count)[0].count * 1.1 ||
                  location?.location?.capacity ||
                  100
              : location?.location?.capacity || 100
          );
          if (!location) return minuteData;
          //TODO: need to set the data from the api call
          dataToSet = [
            ...dataToSet,
            {
              name: `${location.type}: ${location?.location?.name}-${dayStr}`,
              id: `${location?.location?.id}-${dayStr}`,
              // data: datum.count.map((second) =>([+second.timestamp - dateOffset, second.count])),
              data: Object.entries(
                minuteData.counts.reduce((minutes, second) => {
                  // average second data over the minute
                  const minute = Math.floor((second.timestamp - dateOffset) / 60000) * 60000; // beginning of minute
                  return {
                    ...minutes,
                    [minute]: {
                      count: (minutes[minute]?.count ?? 0) + 1,
                      sum: (minutes[minute]?.sum ?? 0) + second.count
                    }
                  };
                }, {})
              ).map(([minute, minuteCount]) => [
                +minute,
                Math.round(minuteCount.sum / minuteCount.count) // return an integer
              ]),
              max: minuteData.counts.length
                ? minuteData.counts.sort((a, b) => b.count - a.count)[0].count * 1.1 ||
                  location?.location?.capacity ||
                  100
                : location?.location?.capacity || 100,
              capacity: location?.location?.capacity,
              area: location?.location?.area,
              color:
                location?.location?.graphColor?.css?.backgroundColor ||
                location?.location?.graphColor ||
                null
            }
          ];
        });
      });

      setData([...dataToSet]);
      setMaxWifiCount(maxWifiCount);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wifiMinutesData, locations, installation, layout]);

    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title="Multiday Count"
          // action={(getWifiMinutesIsLoading && (<CircularProgress />))}
        />
        <CardContent>
          {data.length > 0 && ( // ? (
            <TimeSeriesZoomable
              name={title}
              count={data}
              capacity={maxWifiCount}
              setZoom={setZoom}
              zoom={zoom}
            />
            // ) : (
            //   <CircularProgress />
          )}
        </CardContent>
      </Card>
    );
  }
);

MinuteGraphs.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  installation: PropTypes.object,
  locations: PropTypes.array,
  selectedDates: PropTypes.array,
  layout: PropTypes.string
};

export default MinuteGraphs;
